



import { Component, Vue } from 'vue-property-decorator'
Component.registerHooks([
  'beforeRouteEnter',
])
@Component({ name: 'Transfer' })
export default class Transfer extends Vue {
  beforeRouteEnter(to: any, from: any, next: any) {
    window.open(`/knowledge/${to.name}`)
    // next()
  }
}
